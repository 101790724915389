<template>
<div class="scene">
    Страница не найдена
    <p>
        Вернуться на <a href="/">главную </a>
    </p>
</div>
</template>

<script>
export default {
    name: "PathNotFound"
}
</script>

<style scoped>

</style>
